import { HttpHeaders } from '@angular/common/http';

export function getHeaders(token: string) {
  const headers = new HttpHeaders();
  return headers.set('Content-Type', 'application/json; charset=utf-8').set('Authorization', `Bearer ${token}`);
}

export function objectToUrlParams(obj: any): string {
  return Object.entries(obj)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

export function injectFormId(params: object, formId: string) {
  if (!params) {
    return {
      formId,
    };
  }
  return Object.keys(params).includes('formId')
    ? params
    : {
        ...params,
        formId,
      };
}
