import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { AuthService, Role } from '../services/auth.service';
import { DOCUMENT } from '@angular/common';
import { DashboardService } from './dashboard.service';
import moment from 'moment';
import { SubscriptionInfo, SubscriptionService } from '../services/subscription.service';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { TeamService } from '../services/team.service';

declare let fbq: (...args: any[]) => any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  isEmployer = true;
  hasTeamMembers = false;
  loading = true;
  todaysDate = moment().format('dddd, MMMM Do');
  subscriptionActive = undefined;
  subscriptionPastDue = false;
  subscriptionMessage = '';
  onboardingComplete = false;
  secureAccountComplete = false;

  constructor(
    public auth: AuthService,
    public dashboardService: DashboardService,
    public subscriptionService: SubscriptionService,
    private userService: UserService,
    public router: Router,
    private teamService: TeamService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    const o = this.subscriptionService.getSubscriptionInfo();
    o.subscribe(resp => {
      this.subscriptionActive = resp.subscription.is_valid;
      this.subscriptionPastDue = resp.subscription.status === 'past_due';
      const detail = new SubscriptionInfo();
      detail.subscriptionType = resp.subscription.subscriptionType;
      detail.iap = resp.subscription.iap;
      detail.sub = resp.subscription.sub;
      detail.status = resp.subscription.status;
      detail.is_valid = resp.subscription.is_valid;
      this.subscriptionMessage = detail.getMessage();
    });

    this.auth.role.subscribe(role => {
      this.isEmployer = role === Role.employer;
      this.loading = false;
    });

    this.userService.isOnboardingComplete$.subscribe(isComplete => {
      this.onboardingComplete = isComplete;
    });

    this.userService.isSecureAccountComplete$.subscribe(isComplete => {
      this.secureAccountComplete = isComplete;
    });

    this.teamService.$hasTeamMembers.subscribe(hasTeamMembers => {
      this.hasTeamMembers = hasTeamMembers;
    });
  }

  openAppStore() {
    fbq('track', 'OpenAppStore');
    this.document.location.href = 'https://apps.apple.com/us/app/nest-payroll/id1314304473?mt=8';
    return false;
  }

  onManageTeamClick() {
    this.dashboardService.setFeature('team');
  }

  goToBillingPortal() {
    const referral = `${environment.firebase.server}${this.router.url}`;

    this.subscriptionService.getBillingPortalUrl(referral).subscribe(url => {
      this.document.location.href = url;
    });
  }
}
