import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Paystub, PaystubService } from '../../services/paystub.service';
import { AuthService } from '../../services/auth.service';
import { PaystubDialogComponent } from '../paystub-details/paystub-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getPaystubRowClass } from '../../utils/ui';
import { DashboardService } from '../../dashboard/dashboard.service';
import { UserService } from '../../services/user.service';
import { TeamService } from '../../services/team.service';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import moment from 'moment';

@Component({
  styleUrls: ['paystubs-card.css'],
  selector: 'app-paystubs-card',
  templateUrl: './paystubs-card.component.html',
})
export class PaystubsCardComponent implements OnInit, OnDestroy {
  @Input() isEmployer: boolean;
  loading = true;
  paystubs: Paystub[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    public paystubService: PaystubService,
    private modalService: NgbModal,
    private dashboardService: DashboardService,
    private userService: UserService,
    private teamService: TeamService
  ) {}

  getPaystubRowClass = getPaystubRowClass;

  getPaystubs() {
    this.loading = true;

    this.auth.idToken
      .pipe(
        switchMap(idToken => {
          if (idToken) {
            return this.paystubService.observePaystubs(this.isEmployer);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        async (paystubs: Paystub[]) => {
          if (!paystubs) {
            this.loading = false;
            return;
          }

          this.paystubs = paystubs.sort((a, b) => moment(b.paid_date).diff(moment(a.paid_date)));
          await this.paystubService.loadPaystubNames(this.paystubs, this.isEmployer);

          this.loading = false;
        },
        error => {
          console.error('An error occurred while observing paystubs:', error);
          this.loading = false;
        }
      );
  }

  ngOnInit(): void {
    this.auth.role.subscribe(role => {
      this.teamService.getTeamMembers(role === 'employer').then(() => {
        void this.getPaystubs();
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showPaystub(paystub: Paystub) {
    const modal = this.modalService.open(PaystubDialogComponent, { size: 'md', scrollable: true });
    modal.componentInstance.paystub = paystub;
    modal.componentInstance.isEmployer = this.isEmployer;
    modal.componentInstance.member = this.isEmployer
      ? this.teamService.getHelperInfo(this.userService.getLinkHelperProfileId(paystub.linkId))
      : this.teamService.getEmployerInfo(this.userService.getLinkEmployerProfileId(paystub.linkId));
    modal.componentInstance.otherPartyName = this.paystubService.getPaystubName(paystub, this.isEmployer);
  }

  showAllPaystubs() {
    this.dashboardService.setFeature('paystubs');
  }
}
