import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { getHeaders } from '../utils/http';
import { LinkInfo } from './link.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { SubscriptionService } from './subscription.service';

export interface Address {
  county: string;
  street: string;
  country: string;
  city: string;
  subLocality: string;
  postalCode: string;
  state: string;
  apartment?: string;
}

export interface Profile {
  id: string;
  email?: string;
  firstName: string;
  lastName: string;
  phone: string;
  dateOfBirth?: string;
  address: Address;
  mailingAddress?: Address;
  ssnAvailable?: string; // Last 4 digits of SSN
}

export interface ProfileResponse {
  profile: Profile;
}

export interface ProfilePayload {
  profile: Profile;
  ssn?: string;
}

enum AccountStatus {
  profile = 'profile_incomplete',
  taxInfo = 'taxinfo_incomplete',
  authorizationUndefined = 'authorization_undefined',
  authorizationPending = 'authorization_pending',
  subscription = 'subscription',
}

export interface TaxInfo {
  id: string;
  bankAccount: string;
  bankRouting: string;
}

export interface TaxInfoResponse {
  status: string;
  action: string;
  taxInfos: TaxInfo[];
}

export type ValidationStatus =
  | 'unverified'
  | 'retry'
  | 'kba'
  | 'document'
  | 'unknown'
  | 'pending'
  | 'verified'
  | 'suspended'
  | 'deactivated';

export interface UserConfig {
  authorizationDate: string;
  is_admin: boolean;
  lock: {
    bankAccount: boolean;
    identity: boolean;
  };
  modifiedTime: string;
  profileId: string;
  taxInfoId: string;
  timezone: string;
  validation: {
    method: string;
    status: ValidationStatus;
    timestamp: string;
  };
  hasPassword?: boolean;
}

export interface ConfigResponse {
  status: string;
  action: string;
  config: UserConfig;
}

@Injectable({
  providedIn: 'root',
})
class UserService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private profileUrl = `https://${environment.firebase.server}/app/v2/profile`;
  private taxInfoUrl = `https://${environment.firebase.server}/app/v2/taxInfo`;
  private configUrl = `https://${environment.firebase.server}/app/v1/config`;
  public linkInfos: LinkInfo[] = [];
  private accountStatuses = new BehaviorSubject<AccountStatus[]>(null);
  accountStatuses$ = this.accountStatuses.asObservable();
  private accountStatusRef: any;
  private accountStatusCallback: (snapshot: any) => void;

  currentOnboardingStep$ = this.accountStatuses$.pipe(
    map(statuses => {
      if (!statuses || statuses.length === 0 || statuses.includes(AccountStatus.profile)) {
        return 1;
      }
      if (statuses.includes(AccountStatus.taxInfo)) {
        return 2;
      }
      if (
        statuses.includes(AccountStatus.authorizationUndefined) ||
        statuses.includes(AccountStatus.authorizationPending)
      ) {
        return 3;
      }
      if (statuses.includes(AccountStatus.subscription)) {
        return 4;
      }
    })
  );

  isOnboardingComplete$ = combineLatest([
    this.currentOnboardingStep$,
    this.subscriptionService.subscriptionIsValid$,
  ]).pipe(map(([currentStep, subscriptionActive]) => (currentStep > 2 && subscriptionActive) || currentStep > 3));

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private afDatabase: AngularFireDatabase,
    private subscriptionService: SubscriptionService
  ) {
    void this.getAccountStatuses();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.accountStatusRef && this.accountStatusCallback) {
      this.accountStatusRef.off('value', this.accountStatusCallback);
    }
  }

  getProfile(profileId?: string): Observable<ProfileResponse> {
    return this.auth.idToken.pipe(
      takeUntil(this.destroy$),
      mergeMap(t => {
        if (!t) {
          return of({ profile: {} as Profile });
        }
        return this.http
          .get<ProfileResponse>(this.profileUrl, {
            headers: getHeaders(t),
            params: profileId ? { profileId } : {},
          })
          .pipe(
            catchError(e => {
              console.error('getProfile error', e);
              return of({ profile: {} as Profile });
            })
          );
      })
    );
  }

  updateProfile(profilePayload: ProfilePayload): Observable<ProfileResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        return this.http.put<ProfileResponse>(this.profileUrl, profilePayload, {
          headers: getHeaders(t),
        });
      })
    );
  }

  getTaxInfos(): Observable<TaxInfoResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        if (!t) {
          return of({ taxInfos: [] } as TaxInfoResponse);
        }

        return this.http.get<TaxInfoResponse>(this.taxInfoUrl, {
          headers: getHeaders(t),
        });
      })
    );
  }

  updateTaxInfo(taxInfo: TaxInfo): Observable<TaxInfoResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        return this.http.post<TaxInfoResponse>(this.taxInfoUrl, taxInfo, {
          headers: getHeaders(t),
          params: {
            taxInfoId: taxInfo.id,
          },
        });
      })
    );
  }

  getConfig(): Observable<ConfigResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        if (!t) {
          return of(null);
        }
        return this.http.get<ConfigResponse>(this.configUrl, {
          headers: getHeaders(t),
        });
      })
    );
  }

  getLinkEmployerProfileId(linkId: string): string {
    const info = this.linkInfos.find(l => l.linkId === linkId);
    return info ? info.link.profiles.employer : '';
  }

  getLinkHelperProfileId(linkId: string): string {
    const info = this.linkInfos.find(l => l.linkId === linkId);
    return info ? info.link.profiles.helper : '';
  }

  getAccountStatuses() {
    const userId = this.auth.user.uid;
    this.accountStatusRef = this.afDatabase.database.ref(`/user/${userId}/account_status`);

    this.accountStatusCallback = snapshot => {
      this.accountStatuses.next(snapshot.val());
    };

    this.accountStatusRef.on('value', this.accountStatusCallback);
  }
}

export { UserService };
