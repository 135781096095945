import { Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          padding: '0',
          visibility: 'hidden',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          padding: '1em',
          visibility: 'visible',
        })
      ),
      transition('expanded <=> collapsed', [animate('400ms ease-in-out')]),
    ]),
  ],
})
export class ExpanderComponent {
  @Input() title = '';
  @Input() titleExpanded = '';
  isOpen = false;

  toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
