export function getPaystubDisplayStatus(status: string) {
  if (status === 'paid') {
    return 'PAID';
  }
  if (status === 'review') {
    return 'IN REVIEW';
  }

  return 'UNKNOWN';
}

export function formatPhoneNumber(phone: string) {
  if (!phone) {
    return phone;
  }

  let stripped = toNumbersOnly(phone);

  if (stripped.length === 11 && stripped.charAt(0) === '1') {
    // remove leading 1 if present
    stripped = stripped.substring(1);
  }

  let formatted = '';

  if (stripped.length >= 10) {
    formatted = '(' + stripped.substring(0, 3) + ') ' + stripped.substring(3, 6) + '-' + stripped.substring(6);
  }

  return formatted;
}

export function toNumbersOnly(str: string) {
  return str.replace(/\D/g, '');
}

export function toNumber(value: string): number {
  const parsed = Number(value);
  return !isNaN(parsed) ? parsed : 0;
}

export function toFloat(value: string) {
  return value ? parseFloat(value) : 0;
}

export function stringToHTML(str: string | undefined) {
  return str?.replace(/\n/g, '<br>');
}

export function formatSsn(value: string) {
  return value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
}

export function formatPhoneNumberForFirebase(phoneNumber: string): string {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Ensure the number has the correct length
  if (cleaned.length === 10) {
    // Assume default country code if not provided
    return '+1' + cleaned;
  } else if (cleaned.length > 10 && cleaned.startsWith('1')) {
    // Number includes country code without '+'
    return '+' + cleaned;
  } else {
    // Invalid number length
    throw new Error('Invalid phone number length');
  }
}
