import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormsService } from '../../services/forms.service';
import { DOCUMENT } from '@angular/common';
import { DashboardService } from '../../dashboard/dashboard.service';

@Component({
  selector: 'app-pending-card',
  templateUrl: './pending-card.component.html',
  styleUrls: ['./pending-card.component.css'],
})
export class PendingCardComponent implements OnInit {
  forms: any;

  constructor(
    public auth: AuthService,
    private formsService: FormsService,
    private dashboardService: DashboardService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.formsService.getUserForms(this.auth.user.uid).then(forms => {
      if (forms) {
        this.forms = Object.values(forms).filter((form: any) => !form.submitted && !form.doneTimestamp);
      }
    });
  }

  showAllPending() {
    this.dashboardService.setFeature('pending');
  }
}
