import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TeamMember, TeamService } from '../../services/team.service';
import { PaystubService, YtdHours } from '../../services/paystub.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamMemberPayload, Employment, Link, LinkService } from '../../services/link.service';
import { GeocodingService } from '../../services/geocoding.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import moment from 'moment-timezone';
import { NgForm } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { Address, UserService } from '../../services/user.service';
import { formatPhoneNumber } from '../../utils/string';
import { take } from 'rxjs/operators';
import { maskSSN } from '../../utils/input';

@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          visibility: 'hidden',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          overflow: 'auto',
        })
      ),
      transition('expanded <=> collapsed', [animate('400ms ease-in-out')]),
    ]),
  ],
})
export class TeamEditComponent implements OnInit {
  @Input() member: TeamMember = {
    id: '',
    firstName: '',
    lastName: '',
    linkId: '',
    link: {
      status: '',
      employment: {
        autoEmailPaystub: true,
        w4: {
          status: 'single',
        },
      } as Employment,
      profiles: {
        employer: '',
        helper: '',
        oldHelperProfileId: '',
      },
    } as Link,
    phone: '',
    email: '',
    address: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      county: '',
      country: '',
      subLocality: '',
    },
  };
  @Input() isEmployer: boolean;
  @Input() isNew = false;
  submitted = false;
  ytdHours: YtdHours;
  isAdvancedWithholdingsOpen = false;
  error = '';
  formattedPhone = '';
  formattedDob = '';
  changingAddress = false;
  changingSSN = false;
  changingMailingAddress = false;
  ssn = '';
  locked = false;
  googleOptions: Options = {
    types: ['geocode'],
    componentRestrictions: { country: 'US' },
  } as Options;
  addressComponents = {
    streetNumber: ['street_number'],
    streetName: ['route'],
    county: ['administrative_area_level_2'],
    country: ['country'],
    state: ['administrative_area_level_1'],
    postalCode: ['postal_code'],
    subLocality: ['sublocality_level_1', 'sublocality'],
    city: ['locality'],
    apartment: ['subpremise'],
  };
  profileId: string;
  loading = false;
  minimumWage = 0;

  @ViewChild(NgForm) form!: NgForm;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  jobTitles = ['Nanny', 'Care Giver', 'Nanny Share', 'Cleaner', 'Housekeeper', 'Gardener', 'Other'];

  lat: number;
  lng: number;
  fullAddress = '';

  constructor(
    private modalService: NgbModal,
    private geocodingService: GeocodingService,
    private teamService: TeamService,
    private userService: UserService,
    private paystubService: PaystubService
  ) {}

  ngOnInit() {
    if (this.isNew) {
      return;
    }

    this.ssn = `XXX-XX-${this.member.ssnAvailable}`;
    this.formattedPhone = this.member.phone;

    this.fullAddress = `${this.member.address.street}, ${this.member.address.city}, ${this.member.address.state} ${this.member.address.postalCode}`;

    this.geocodingService.getLatLong(this.fullAddress).subscribe(
      coordinates => {
        this.lat = coordinates.lat;
        this.lng = coordinates.lng;
      },
      error => console.error(error)
    );

    this.userService
      .getConfig()
      .pipe(take(1))
      .subscribe(
        configRes => {
          this.profileId = configRes?.config?.profileId;
        },
        () => {}
      );

    this.ytdHours = this.paystubService.getYtdHours();
  }

  toggleAdvancedWithholdings(): void {
    this.isAdvancedWithholdingsOpen = !this.isAdvancedWithholdingsOpen;
  }

  close() {
    this.modalService.dismissAll();
  }

  changeSSN() {
    this.changingSSN = true;
    this.ssn = '';
  }

  cancelChangeSSN() {
    this.changingSSN = false;
    if (this.member.ssnAvailable) {
      this.ssn = `XXX-XX-${this.member.ssnAvailable}`;
    }
  }

  formatAddress(address: Address) {
    return `${address?.street ?? ''}${address?.apartment ? ` ${address?.apartment}, ` : ''}, ${
      address?.city ? `${address.city}, ` : ''
    }${address?.state ? `${address.state} ` : ''}${address?.postalCode ?? ''}`;
  }

  getComponent = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.long_name ?? '';

  getComponentShort = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.short_name ?? '';

  handleAddressChange(address: GoogleAddress) {
    this.minimumWage = 0;

    const getComponent = (types: string[]) => this.getComponent(address, types);
    const getComponentShort = (types: string[]) => this.getComponentShort(address, types);

    const streetNumber = getComponent(this.addressComponents.streetNumber);
    const streetName = getComponentShort(this.addressComponents.streetName);

    this.member.address.street = `${streetNumber} ${streetName}`;
    this.member.address.county = getComponent(this.addressComponents.county);
    this.member.address.country = getComponentShort(this.addressComponents.country);
    this.member.address.state = getComponentShort(this.addressComponents.state);
    this.member.address.postalCode = getComponent(this.addressComponents.postalCode);
    this.member.address.subLocality = getComponent(this.addressComponents.subLocality);
    // Addresses in NYC don't have a city, but do have a sublocality
    this.member.address.city = getComponent(this.addressComponents.city) || this.member.address.subLocality;
    this.member.address.apartment = getComponent(this.addressComponents.apartment);
    this.fullAddress = this.formatAddress(this.member.address);

    this.teamService
      .getMinimumWage(this.member.address.state)
      .pipe(take(1))
      .subscribe(
        res => {
          this.minimumWage = res.wageInfo.wage;
        },
        error => {
          console.error(error);
        }
      );
  }

  isStartDateOutsideQuarter() {
    if (!this.member.link?.employment?.startDate) {
      return false;
    }

    const startDate = moment(this.member.link.employment.startDate);
    return startDate.isAfter(moment().endOf('quarter')) || startDate.isBefore(moment().startOf('quarter'));
  }

  async onSubmit() {
    this.error = '';
    this.submitted = true;

    console.log('submitted', this.member);

    if (this.form.valid) {
      this.loading = true;

      this.changingAddress = false;
      this.changingMailingAddress = false;
      this.changingSSN = false;

      const date = moment.tz(this.formattedDob, moment.tz.guess());
      this.member.dateOfBirth = date.isValid() ? date.toISOString() : '';
      this.member.phone = formatPhoneNumber(this.formattedPhone);

      // const payload = this.ssn.includes('X') ? { profile: updatedProfile } : { profile: updatedProfile, ssn: this.ssn };

      // this.ssn = this.ssn ? maskSSN(this.ssn) : '';

      const teamMemberPayload: TeamMemberPayload = {
        status: 'active',
        ssn: this.ssn,
        linkData: {
          employment: this.member.link.employment,
        },
        flags: {
          overrideStateCheck: false,
          movePayroll: false,
          catchupCombined: false,
          newHireReport: true,
          setupDirectDeposit: false,
        },
        profile: {
          firstName: this.member.firstName,
          lastName: this.member.lastName,
          phone: this.member.phone,
          email: this.member.email,
          address: this.member.address,
        },
      } as TeamMemberPayload;

      console.log('payload', teamMemberPayload);

      if (this.isNew) {
        this.teamService
          .createTeamMember(teamMemberPayload)
          .pipe(take(1))
          .subscribe(
            link => {
              this.teamService.teamMembersUpdated();
              this.loading = false;
              this.close();
            },
            error => {
              console.error(`Error creating link for ${this.member.id}`, error);
              this.error = error.error.message;
              this.loading = false;
            }
          );
      } else {
        this.teamService
          .updateTeamMember(teamMemberPayload)
          .pipe(take(1))
          .subscribe(
            link => {
              this.teamService.teamMembersUpdated();
              this.loading = false;
              this.close();
            },
            error => {
              console.error(`Error creating link for ${this.member.id}`, error);
              this.error = error.error.message;
              this.loading = false;
            }
          );
      }

      this.submitted = false;
    }
  }
}
