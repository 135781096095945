import { Component, DoCheck, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';

import { formatSsn, toNumber } from '../../utils/string';
import { getIsHidden } from 'src/app/utils/ui';

@Component({
  selector: 'app-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.css'],
})
export class DynamicFieldComponent implements OnInit, OnDestroy, OnChanges, DoCheck {
  @Input() data: any;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() parentHidden: boolean;
  private valueChangeSubscription: Subscription;
  mask = '';
  isHidden = false;
  invalid = false;
  control: FormControl;
  uuid = uuidv4();

  private previousValue: any;
  protected readonly getIsHidden = getIsHidden;

  constructor() {}

  ngOnInit() {
    this.setupControl();

    this.isHidden = this.getIsHidden(this.data.hidden) || this.parentHidden;
  }

  ngOnDestroy() {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  ngDoCheck() {
    this.updateValidators();

    if (this.formValueChanged()) {
      if (this.data.param === 'ssn' && this.form.value.ssn) {
        this.form.setValue({ ...this.form.value, ssn: formatSsn(this.form.value.ssn) });
      }
      this.isHidden = this.getIsHidden(this.data.hidden) || this.parentHidden;
      this.previousValue = { ...this.form.value };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.mask = this.getMask(this.data);
      this.isHidden = this.getIsHidden(this.data.hidden) || this.parentHidden;
      this.invalid = this.isInvalid();
    }
  }

  setupControl() {
    this.updateValidators();

    if (this.form && this.data.param) {
      if (this.valueChangeSubscription) {
        this.valueChangeSubscription.unsubscribe();
      }

      // Subscribe to value changes
      this.valueChangeSubscription = this.form.get(this.data.param).valueChanges.subscribe(value => {
        if (this.data.type === 'hours' || this.data.type === 'number') {
          const numericValue = toNumber(value);

          // Use patchValue to update the form control, and set emitEvent to false to prevent an infinite loop
          this.form.get(this.data.param).patchValue(numericValue, { emitEvent: false });
        }
      });

      this.previousValue = this.form.get(this.data.param)?.value;
    }
  }

  formValueChanged(): boolean {
    return JSON.stringify(this.previousValue) !== JSON.stringify(this.form.value);
  }

  isInvalid() {
    return this.form.get(this.data.param.toString())?.invalid && this.submitted;
  }

  getMask(data: any) {
    if (data.options?.format === 'phone') {
      return '(000) 000-0000';
    }
    return (
      data.options?.format
        ?.replace(/L/g, 'S') // L is for lowercase letters, S is any letters
        ?.replace(/9/g, '0') ?? // 9 is for optional numbers, 0 is for required numbers. 9 causes double dashes to appear.
      ''
    );
  }

  updateValidators() {
    const control = this.form.get(this.data.param);

    if (!this.isHidden && !this.parentHidden && this.data.required) {
      control.setValidators(Validators.required);
    } else {
      control?.setValidators(null); // Remove validators if not required or hidden
    }

    control?.updateValueAndValidity({ onlySelf: true, emitEvent: true });

    // Force the entire form to re-evaluate its validity
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).updateValueAndValidity({ onlySelf: true, emitEvent: true });
    });
  }
}
