export function getPaystubDisplayStatus(status: string) {
  if (status === 'paid') {
    return 'PAID';
  }
  if (status === 'review') {
    return 'IN REVIEW';
  }

  return 'UNKNOWN';
}

export function formatPhoneNumber(phone: string) {
  if (!phone) {
    return phone;
  }

  let stripped = toNumbersOnly(phone);

  if (stripped.length === 11 && stripped.charAt(0) === '1') {
    // remove leading 1 if present
    stripped = stripped.substring(1);
  }

  let formatted = '';

  if (stripped.length >= 10) {
    formatted = '(' + stripped.substring(0, 3) + ') ' + stripped.substring(3, 6) + '-' + stripped.substring(6);
  }

  return formatted;
}

export function toNumbersOnly(str: string) {
  return str.replace(/\D/g, '');
}

export function stringToHTML(str: string | undefined) {
  return str?.replace(/\n/g, '<br>');
}

export function stringToNumber(value: string): number {
  const parsed = Number(value);
  return !isNaN(parsed) ? parsed : 0;
}

export function formatSsn(value: string) {
  return value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
}
