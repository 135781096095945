import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageBoxComponent } from '../components/message-box/message-box.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modal: NgbModal) {}

  public showMessage(title: string, message: string | SafeHtml) {
    const modal = this.modal.open(MessageBoxComponent);
    modal.componentInstance.title = title;
    modal.componentInstance.message = message;

    return modal;
  }

  public showConfirmation(title: string, message: string | SafeHtml, confirmText = 'OK', cancelText = 'Cancel') {
    const modal = this.modal.open(ConfirmationDialogComponent);
    modal.componentInstance.title = title;
    modal.componentInstance.message = message;
    modal.componentInstance.confirmText = confirmText;
    modal.componentInstance.cancelText = cancelText;

    return modal;
  }
}
