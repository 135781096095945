import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PaystubCreateComponent } from '../paystubs/paystub-create/paystub-create.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamMember, TeamService } from '../services/team.service';
import { noop } from 'rxjs';
import { TeamEditComponent } from './edit/team-edit.component';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css'],
})
export class TeamComponent implements OnInit {
  loading = true;
  teamMembers: TeamMember[] = [];
  isEmployer = false;

  protected readonly noop = noop;

  constructor(public auth: AuthService, public teamService: TeamService, private modalService: NgbModal) {}

  async getTeamMembers() {
    this.loading = true;

    this.auth.role.subscribe(async role => {
      this.isEmployer = role === 'employer';

      if (this.auth.user?.uid) {
        if (role === 'employer') {
          this.teamService.helpers$.subscribe(helpers => {
            this.teamMembers = helpers.filter(t => t.link.status === 'active');
            this.loading = false;
          });
        } else {
          this.teamService.employers$.subscribe(employers => {
            this.teamMembers = employers.filter(t => t.link.status === 'active');
            this.loading = false;
          });
        }

        await this.teamService.getTeamMembers(role === 'employer');
      }
    });
  }

  ngOnInit(): void {
    void this.getTeamMembers();
  }

  showNewPaystubDialog(member: TeamMember) {
    const modal = this.modalService.open(PaystubCreateComponent, { size: 'md', scrollable: true });
    modal.componentInstance.member = member;
  }

  addTeamMember() {
    const modal = this.modalService.open(TeamEditComponent, { size: 'lg', scrollable: true });
    modal.componentInstance.isNew = true;
  }
}
