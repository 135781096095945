import { Injectable } from '@angular/core';
import { AuthService, Role } from './auth.service';
import { Link, LinkService, TeamMemberPayload } from './link.service';
import { Address, UserService } from './user.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { getHeaders } from '../utils/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface TeamMember {
  id: string;
  firstName: string;
  lastName: string;
  linkId: string;
  link: Link;
  phone: string;
  email: string;
  ssn?: string;
  ssnAvailable?: string;
  dateOfBirth?: string;
  address: Address;
}

interface WageResponse {
  status: string;
  wageInfo: {
    region: string;
    wage: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private serverUrl = `https://${environment.firebase.server}/app/v1`;

  public employers: TeamMember[] = [];
  public helpers: TeamMember[] = [];
  public teamMembersLoading = false;
  loadedProfileIds: string[] = [];

  private _employers = new BehaviorSubject<TeamMember[]>([]);
  private _helpers = new BehaviorSubject<TeamMember[]>([]);
  private _hasTeamMembers = new BehaviorSubject<boolean>(false);

  public employers$: Observable<TeamMember[]> = this._employers.asObservable();
  public helpers$: Observable<TeamMember[]> = this._helpers.asObservable();
  public $hasTeamMembers: Observable<boolean> = this._hasTeamMembers.asObservable();

  private teamMembersUpdate = new Subject<void>();

  teamMembersUpdate$ = this.teamMembersUpdate.asObservable();

  constructor(
    private auth: AuthService,
    private linkService: LinkService,
    private userService: UserService,
    private http: HttpClient
  ) {}

  teamMembersUpdated() {
    this.teamMembersUpdate.next();
  }

  getEmployerInfo = (id: string): TeamMember => this.employers.find(u => u.id === id);

  getHelperInfo = (id: string): TeamMember => this.helpers.find(u => u.id === id);

  linksLoadedForAllHelpers = () =>
    this.userService.linkInfos.every(
      l => l.link.profiles.helper && this.helpers.map(h => h.id).includes(l.link.profiles.helper)
    );

  linksLoadedForAllEmployers = () =>
    this.userService.linkInfos.every(
      l => l.link.profiles.employer && this.employers.map(e => e.id).includes(l.link.profiles.employer)
    );

  createTeamMember(payload: TeamMemberPayload): Observable<TeamMemberPayload> {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.post<TeamMemberPayload>(`${this.serverUrl}/teamMember`, payload, {
          headers: getHeaders(t),
        })
      )
    );
  }

  updateTeamMember(payload: TeamMemberPayload, linkId: string): Observable<TeamMemberPayload> {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.put<TeamMemberPayload>(`${this.serverUrl}/teamMember/${linkId}`, payload, {
          headers: getHeaders(t),
        })
      )
    );
  }

  async getTeamMembers(isEmployer: boolean) {
    if (!this.auth.user || this.teamMembersLoading) {
      return [];
    }

    this.teamMembersLoading = true;

    return new Promise<TeamMember[]>(async (resolve, reject) => {
      const linkRecord = await this.linkService.getLink(this.auth.user.uid, isEmployer ? Role.employer : Role.helper);

      if (!linkRecord) {
        this.teamMembersLoading = false;
        resolve([]);
        return;
      }

      Object.entries(linkRecord)
        .sort(([_, a], [__, b]) => {
          const statusA = (a as Link).status;
          const statusB = (b as Link).status;
          return statusA === 'active' && statusB !== 'active'
            ? -1
            : statusA !== 'active' && statusB === 'active'
            ? 1
            : 0;
        })
        .map(([linkId, link]) => this.userService.linkInfos.push({ link: link as Link, linkId }));

      this.userService.linkInfos.map(({ linkId, link }) => {
        const profileId = isEmployer ? link.profiles.helper : link.profiles.employer;

        if (this.loadedProfileIds.includes(profileId)) {
          return;
        }

        this.loadedProfileIds.push(profileId);

        this.userService
          .getProfile(profileId)
          .subscribe(
            ({ profile: { firstName, lastName, address, phone, email, ssnAvailable, dateOfBirth, ...rest } }) => {
              try {
                if (link.profiles.employer && !this.getEmployerInfo(link.profiles.employer)) {
                  this.employers.push({
                    id: link.profiles.employer,
                    linkId,
                    firstName,
                    lastName,
                    address,
                    phone,
                    email,
                    link,
                    ssnAvailable,
                    dateOfBirth,
                  });
                }
                if (link.profiles.helper && !this.getHelperInfo(link.profiles.helper)) {
                  this.helpers.push({
                    id: link.profiles.helper,
                    linkId,
                    firstName,
                    lastName,
                    address,
                    phone,
                    email,
                    link,
                    ssnAvailable,
                    dateOfBirth,
                  });
                }
              } catch (e) {
                console.log('getProfile error', e);
                reject(e);
              }

              if (isEmployer && this.linksLoadedForAllHelpers()) {
                this._helpers.next(this.helpers);
                this._hasTeamMembers.next(this.helpers.length > 0);
                this.teamMembersLoading = false;
                resolve(this.helpers);
              } else if (!isEmployer && this.linksLoadedForAllEmployers()) {
                this._employers.next(this.employers);
                resolve(this.employers);
                this.teamMembersLoading = false;
                this._hasTeamMembers.next(this.employers.length > 0);
              } else {
                console.warn('no team member found for profileId', profileId);
              }
            }
          );
      });
    });
  }

  public getMinimumWage(state: string) {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.get<WageResponse>(`${this.serverUrl}/minimumWage`, {
          headers: getHeaders(t),
          params: {
            state,
          },
        })
      )
    );
  }

  clearTeamMembers() {
    this.employers = [];
    this.helpers = [];
    this._employers.next([]);
    this._helpers.next([]);
    this._hasTeamMembers.next(false);
    this.teamMembersUpdated();
  }
}
