import { Component, Input, OnInit } from '@angular/core';
import { TeamMember, TeamService } from '../../services/team.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeocodingService } from '../../services/geocoding.service';
import { take } from 'rxjs/operators';
import { LinkService } from '../../services/link.service';
import { PaystubService, YtdHours } from '../../services/paystub.service';
import { TeamEditComponent } from '../edit/team-edit.component';

type Features = 'paystubs';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.css'],
})
export class TeamDetailsComponent implements OnInit {
  @Input() member: TeamMember;
  @Input() isEmployer: boolean;
  error = '';
  feature: Features = 'paystubs';
  showDetails = false;
  showAnimations = false;
  ytdHours: YtdHours = {
    bonus: 0,
    doubletime: 0,
    hours: 0,
    incidentals: {
      healthCare: 0,
      other: 0,
    },
    incidentalsAmount: 0,
    overtime: 0,
    secondaryHours: 0,
    sickBalance: 0,
    sickHours: 0,
    vacationBalance: 0,
    vacationHours: 0,
  };

  lat: number;
  lng: number;
  fullAddress = '';

  constructor(
    private modalService: NgbModal,
    private linkService: LinkService,
    private geocodingService: GeocodingService,
    private teamService: TeamService,
    private paystubService: PaystubService
  ) {}

  ngOnInit() {
    this.fullAddress = `${this.member.address.street}, ${this.member.address.city}, ${this.member.address.state} ${this.member.address.postalCode}`;

    this.geocodingService.getLatLong(this.fullAddress).subscribe(
      coordinates => {
        this.lat = coordinates.lat;
        this.lng = coordinates.lng;
      },
      error => console.error(error)
    );

    const ytdHours = this.paystubService.getYtdHours();
    if (ytdHours) {
      this.ytdHours = ytdHours;
    }
  }

  selectFeature(feature: Features) {
    this.feature = feature;
    this.showDetails = true;
    this.showAnimations = true;
  }

  setStatus(status: string) {
    const { linkId, link, ...rest } = this.member;
    const { profiles, ...linkRest } = link;

    return this.linkService
      .updateLink(linkId, {
        ...linkRest,
        profiles: {
          ...profiles,
          employer: rest.id,
        },
        status,
      })
      .then(() => {
        this.member.link.status = status;
        this.teamService.teamMembersUpdated();
      })
      .catch(error => {
        console.error(`Error updating link status: ${error}`);
        this.error = 'The status could not be updated. Please try again.';
      });
  }

  handleDeactivate() {
    this.setStatus('inactive');
  }

  handleReactivate() {
    this.setStatus('active');
  }

  handleTerminate() {
    this.setStatus('terminated');
  }

  onEditClick() {
    this.modalService.dismissAll();

    const modalRef = this.modalService.open(TeamEditComponent, {
      size: 'lg',
      scrollable: true,
    });

    modalRef.componentInstance.member = this.member;

    modalRef.closed.pipe(take(1)).subscribe(() => {
      this.teamService.teamMembersUpdated();
    });
  }

  onBackClick() {
    this.showDetails = false;
  }

  close() {
    this.modalService.dismissAll();
  }
}
