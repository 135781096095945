import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { LandingComponent } from './landing/landing.component';
import { ActivationComponent } from './activation/activation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { InvoicesComponent } from './dashboard/invoices/invoices.component';
import { InvoiceItemComponent } from './dashboard/invoices/item/item.component';
import { HeaderComponent } from './dashboard/header/header.component';
import { EditPaymentComponent } from './dashboard/edit-payment/edit-payment.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CookieService } from 'ngx-cookie-service';
import { ReferralManagerComponent } from './referral-manager/referral-manager.component';
import { ReferralItemComponent } from './referral-manager/item/item.component';
import { LoaderComponent } from './loader/loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LinkRequestComponent } from './link-request/link-request.component';
import { LinkSignInComponent } from './link-sign-in/link-sign-in.component';
import { AuthActionComponent } from './auth-action/auth-action.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RevertSecondFactorComponent } from './revert-second-factor/revert-second-factor.component';
import { BankAccountDetailsComponent } from './bank-account-details/bank-account-details.component';
import { CardComponent } from './dashboard/card/card.component';
import { PaystubsListComponent } from './paystubs/paystubs-list/paystubs-list.component';
import { PaystubDetailsComponent } from './paystubs/paystub-details/paystub-details.component';
import { PaystubStatusPipe } from './pipes/paystub-status.pipe';
import { PaystubDialogComponent } from './paystubs/paystub-details/paystub-dialog.component';
import { ProfileDialogComponent } from './profile/profile-dialog.component';
import { ProfileComponent } from './profile/profile.component';
import { AgmCoreModule, MapsAPILoader, GoogleMapsScriptProtocol, LazyMapsAPILoader } from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PhonePipe } from './pipes/phone.pipe';
import { ValidationStatusPipe } from './pipes/validation-status.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { SegmentControlComponent } from './segment-control/segment-control.component';
import { PaystubsCardComponent } from './paystubs/paystubs-card/paystubs-card.component';
import { MasterDetailsComponent } from './master-details/master-details.component';
import { MasterComponent } from './master-details/master.component';
import { DetailsComponent } from './master-details/details.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TeamComponent } from './team/team.component';
import { TeamListComponent } from './team/list/team-list.component';
import { PaystubCreateComponent } from './paystubs/paystub-create/paystub-create.component';
import { ToggleInputComponent } from './components/toggle-input/toggle-input.component';
import { ToggleInputFieldComponent } from './components/toggle-input-field/toggle-input-field.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { PageComponent } from './components/page/page.component';
import { DynamicFieldComponent } from './components/dynamic-field/dynamic-field.component';
import { CommonModule, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormArrayInputComponent } from './components/form-array-input/form-array-input.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { PaystubPayComponent } from './paystubs/paystub-pay/paystub-pay.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SubscriptionInactiveDialogComponent } from './subscription/subscription-inactive-dialog/subscription-inactive-dialog.component';
import { TeamDetailsComponent } from './team/details/team-details.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { TestPageComponent } from './components/page/tests/test-page/test-page.component';
import { DynamicFormsIndexComponent } from './components/page/tests/dynamic-forms-index/dynamic-forms-index.component';
import { MarkdownModule } from 'ngx-markdown';
import { StringToHtmlPipe } from './pipes/string-to-html.pipe';
import { PendingCardComponent } from './pending/pending-card/pending-card.component';
import { PendingListComponent } from './pending/pending-list/pending-list.component';
import { TitleHeaderComponent } from './dashboard/title-header/title-header.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { AddressComponent } from './components/address/address.component';
import { DashboardOldComponent } from './dashboard-old/dashboard.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { ToolkitCardComponent } from './toolkit/toolkit-card/toolkit-card.component';
import { ToolkitComponent } from './toolkit/toolkit/toolkit.component';
import { HeaderOldComponent } from './dashboard-old/header/header.component';
import { SecureAccountComponent } from './secure-account/secure-account.component';
import { StepListComponent } from './components/step-list/step-list.component';
import { TeamEditComponent } from './team/edit/team-edit.component';
import { FormatCurrencyDirective } from './components/format-currency/format-currency.directive';
import { ExpanderComponent } from './components/expander/expander.component';
import { ButtonGroupDirective } from './components/button-group/button-group.directive';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SubscribeComponent,
    LandingComponent,
    ActivationComponent,
    DashboardComponent,
    DashboardOldComponent,
    LoginComponent,
    InvoicesComponent,
    InvoiceItemComponent,
    HeaderComponent,
    HeaderOldComponent,
    EditPaymentComponent,
    SafeHtmlPipe,
    ForgotPasswordComponent,
    ReferralManagerComponent,
    ReferralItemComponent,
    LoaderComponent,
    LinkRequestComponent,
    LinkSignInComponent,
    AuthActionComponent,
    VerifyEmailComponent,
    ResetPasswordComponent,
    RevertSecondFactorComponent,
    BankAccountDetailsComponent,
    CardComponent,
    PaystubsListComponent,
    PaystubDetailsComponent,
    PaystubsCardComponent,
    PaystubStatusPipe,
    PaystubDialogComponent,
    ProfileDialogComponent,
    ProfileComponent,
    PhonePipe,
    ValidationStatusPipe,
    LocalDatePipe,
    SegmentControlComponent,
    MasterDetailsComponent,
    MasterComponent,
    DetailsComponent,
    SubscriptionComponent,
    TeamComponent,
    TeamListComponent,
    SignupComponent,
    PaystubCreateComponent,
    ToggleInputComponent,
    ToggleInputFieldComponent,
    PageComponent,
    DynamicFieldComponent,
    FormInputComponent,
    FormArrayInputComponent,
    ConfirmationDialogComponent,
    MessageBoxComponent,
    PaystubPayComponent,
    SubscriptionInactiveDialogComponent,
    TeamDetailsComponent,
    AvatarComponent,
    TestPageComponent,
    DynamicFormsIndexComponent,
    StringToHtmlPipe,
    PendingCardComponent,
    PendingListComponent,
    TitleHeaderComponent,
    FileUploadComponent,
    AddressComponent,
    OnboardingComponent,
    ToolkitCardComponent,
    ToolkitComponent,
    SecureAccountComponent,
    StepListComponent,
    TeamEditComponent,
    FormatCurrencyDirective,
    ExpanderComponent,
    ButtonGroupDirective,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireDatabaseModule,
    NgbModule, // imports firebase/database, only needed for database features
    AgmCoreModule.forRoot({
      apiKey: environment.google.placesKey,
      libraries: ['places'],
    }),
    GooglePlaceModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    ClipboardModule,
    MarkdownModule.forRoot(),
    NgOptimizedImage,
    DateRangeComponent,
  ],
  providers: [
    CookieService,
    { provide: MapsAPILoader, useClass: LazyMapsAPILoader },
    { provide: GoogleMapsScriptProtocol, useValue: GoogleMapsScriptProtocol.HTTPS },
    provideEnvironmentNgxMask(),
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
