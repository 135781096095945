import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { take } from 'rxjs/operators';
import { ModalService } from '../services/modal.service';
import { StepListItem } from '../components/step-list/step-list.component';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { formatPhoneNumberForFirebase } from '../utils/string';

@Component({
  selector: 'app-secure-account',
  templateUrl: './secure-account.component.html',
  styleUrls: ['./secure-account.component.css'],
})
export class SecureAccountComponent implements OnInit, OnDestroy {
  isOnboardingComplete = false;
  isEmailVerified = false;
  isMfaEnabled = false;
  profileId = '';
  phoneNumber = '';
  recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  constructor(public userService: UserService, private auth: AuthService, private modal: ModalService) {}

  ngOnInit() {
    this.userService.isOnboardingComplete$.subscribe(isComplete => {
      this.isOnboardingComplete = isComplete;
    });

    this.userService.isEmailVerified$.subscribe(isEmailVerified => {
      this.isEmailVerified = isEmailVerified;
    });

    this.userService.isMFAEnabled$.subscribe(isMfaEnabled => {
      this.isMfaEnabled = isMfaEnabled;
    });

    this.userService
      .getConfig()
      .pipe(take(1))
      .subscribe(configRes => {
        this.profileId = configRes?.config?.profileId;
      });

    this.userService
      .getProfile()
      .pipe(take(1))
      .subscribe(({ profile: { phone } }) => {
        this.phoneNumber = phone;
      });

    this.renderRecaptcha();
  }

  ngOnDestroy() {
    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear();
    }
  }

  renderRecaptcha() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: response => {
        console.log('reCAPTCHA solved');
      },
      'expired-callback': () => {
        this.recaptchaVerifier.clear();
      },
    });
  }

  getItems(): StepListItem[] {
    return [
      { id: 1, title: 'Verify Email', status: this.isEmailVerified ? 'done' : 'current' },
      {
        id: 2,
        title: 'Turn on 2-Step Verification',
        status: this.isMfaEnabled ? 'done' : this.isEmailVerified ? 'current' : 'incomplete',
      },
    ];
  }

  verifyEmail() {
    this.modal
      .showConfirmation(
        'Verify Email',
        `This will send a verification email to ${this.auth.user?.email}.`,
        'Send Email',
        'Cancel'
      )
      .result.then(result => {
        if (!result) {
          return;
        }

        this.userService.sendEmailVerification();
      });
  }

  async continue() {
    if (!this.isEmailVerified) {
      this.verifyEmail();
    } else {
      const result = await this.userService.enable2FAWithSMS(
        formatPhoneNumberForFirebase(this.phoneNumber),
        this.recaptchaVerifier
      );

      if (!result) {
        alert('2-Step Verification enabled');
        this.isMfaEnabled = true;
      } else {
        if (result.code === 'auth/requires-recent-login') {
          alert('Please log in again before enabling 2-Step Verification.');
          void this.auth.signOut();
        } else if (result.code === 'auth/invalid-verification-code') {
          alert('The code you provided was not recognized. Please try again.');
        } else if (result.code === 'auth/too-many-requests') {
          alert(result.message);
        } else if (result.code === 'auth/credential-already-in-use') {
          alert('This phone number is already in use. Please try again.');
        } else if (result.code === 'auth/missing-phone-number' || result.code === 'auth/invalid-phone-number') {
          alert('Please provide a valid phone number in your user profile.');
        } else if (result.code === 'auth/code-expired') {
          alert('The code you provided has expired. Please try again.');
        }
      }
    }
  }
}
