import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { TeamService } from '../services/team.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-secure-account',
  templateUrl: './secure-account.component.html',
  styleUrls: ['./secure-account.component.css'],
})
export class SecureAccountComponent implements OnInit {
  onboardingComplete = false;
  isEmployer = false;
  profileId = '';
  currentStep = 1;

  items = [
    { id: 1, title: 'Verify Email', completed: true },
    { id: 2, title: 'Turn on 2-Step Verification', completed: false },
  ];

  constructor(public userService: UserService, private auth: AuthService, private teamService: TeamService) {}

  ngOnInit() {
    this.userService.isOnboardingComplete$.subscribe(isComplete => {
      this.onboardingComplete = isComplete;
    });

    this.userService
      .getConfig()
      .pipe(take(1))
      .subscribe(configRes => {
        this.profileId = configRes?.config?.profileId;
      });
  }

  continue() {
    window.location.href = `/forms/kycVerificationForm?profileId=${this.profileId}&firstName=`;
  }
}
