import { Component, Input, OnInit } from '@angular/core';
import { GeocodingService } from '../../services/geocoding.service';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Address } from '../../services/user.service';
import { MapsAPILoader } from '@agm/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent implements OnInit {
  @Input() address: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    county: string;
    country: string;
    subLocality: string;
    apartment: string;
  };
  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() submitted: boolean;
  @Input() required: boolean;

  lat: number;
  lng: number;
  fullAddress = '';
  changingAddress = false;
  error = false;
  googleOptions: Options = {
    types: ['geocode'],
    componentRestrictions: { country: 'US' },
  } as Options;
  addressComponents = {
    streetNumber: ['street_number'],
    streetName: ['route'],
    county: ['administrative_area_level_2'],
    country: ['country'],
    state: ['administrative_area_level_1'],
    postalCode: ['postal_code'],
    subLocality: ['sublocality_level_1', 'sublocality'],
    city: ['locality'],
    apartment: ['subpremise'],
  };

  protected readonly Object = Object;

  constructor(private geocodingService: GeocodingService, private mapsAPILoader: MapsAPILoader) {}

  formatAddress(address: Address) {
    return `${address?.street ?? ''}${address?.apartment ? ` ${address?.apartment}, ` : ''}, ${
      address?.city ? `${address.city}, ` : ''
    }${address?.state ? `${address.state} ` : ''}${address?.postalCode ?? ''}`;
  }

  updateAddress() {
    if (this.address && this.address.street && this.address.city && this.address.state && this.address.postalCode) {
      this.fullAddress = this.formatAddress(this.address);
      this.group.get(this.controlName).setValue(this.address);
    } else {
      this.fullAddress = '';
      this.group.get(this.controlName).setValue({});
    }
  }

  ngOnInit() {
    this.updateAddress();

    if (this.fullAddress) {
      this.geocodingService.getLatLong(this.fullAddress).subscribe(
        coordinates => {
          this.lat = coordinates.lat;
          this.lng = coordinates.lng;
        },
        error => console.error(error)
      );
    }

    void this.mapsAPILoader.load();
  }

  cancelChangeAddress() {
    this.changingAddress = false;
    this.updateAddress();
  }

  getComponent = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.long_name ?? '';

  getComponentShort = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.short_name ?? '';

  handleAddressChange(address: GoogleAddress) {
    const getComponent = (types: string[]) => this.getComponent(address, types);
    const getComponentShort = (types: string[]) => this.getComponentShort(address, types);

    const streetNumber = getComponent(this.addressComponents.streetNumber);
    const streetName = getComponentShort(this.addressComponents.streetName);

    if (!this.address) {
      this.address = {} as any;
    }

    this.address.street = `${streetNumber} ${streetName}`;
    this.address.county = getComponent(this.addressComponents.county);
    this.address.country = getComponentShort(this.addressComponents.country);
    this.address.state = getComponentShort(this.addressComponents.state);
    this.address.postalCode = getComponent(this.addressComponents.postalCode);
    this.address.subLocality = getComponent(this.addressComponents.subLocality);
    // Addresses in NYC don't have a city, but do have a sublocality
    this.address.city = getComponent(this.addressComponents.city) || this.address.subLocality;
    this.address.apartment = getComponent(this.addressComponents.apartment);

    this.updateAddress();

    this.changingAddress = false;

    this.geocodingService.getLatLong(this.fullAddress).subscribe(
      coordinates => {
        this.lat = coordinates.lat;
        this.lng = coordinates.lng;
      },
      error => console.error(error)
    );
  }

  isInvalid() {
    return this.group.get(this.controlName)?.invalid && this.submitted;
  }
}
