import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { getHeaders } from '../utils/http';

@Injectable({
  providedIn: 'root',
})
class BankService {
  private routingUrl = `https://${environment.firebase.server}/app/v1/routing`;

  constructor(private auth: AuthService, private http: HttpClient) {}

  validateRoutingNumber(rn: string): Observable<RoutingInfo> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        return this.http.get<RoutingInfo>(this.routingUrl, {
          headers: getHeaders(t),
          params: {
            rn,
          },
        });
      })
    );
  }
}

class Address {
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

class RoutingInfo {
  address: Address;
  name: string;
  phone: string;
}

export { BankService, RoutingInfo };
