import { Component, Input, OnInit } from '@angular/core';
import { Paystub, PaystubService } from '../../services/paystub.service';
import { AuthService } from '../../services/auth.service';
import { PaystubDialogComponent } from '../paystub-details/paystub-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { getPaystubRowClass } from '../../utils/ui';
import { DashboardService } from '../../dashboard/dashboard.service';
import { UserService } from '../../services/user.service';
import { TeamService } from '../../services/team.service';

interface PaystubGroup {
  title: string;
  paystubs: Paystub[];
}

@Component({
  selector: 'app-paystubs-list',
  templateUrl: './paystubs-list.component.html',
  styleUrls: ['./paystubs-list.component.css'],
})
export class PaystubsListComponent implements OnInit {
  @Input() isEmployer: boolean;
  @Input() linkId: string;
  loading = true;
  paystubs: Paystub[] = [];
  groups: PaystubGroup[] = [];

  constructor(
    public auth: AuthService,
    public paystubService: PaystubService,
    private modalService: NgbModal,
    public dashboardService: DashboardService,
    private userService: UserService,
    private teamService: TeamService
  ) {}

  getPaystubRowClass = getPaystubRowClass;

  createPaystubGroups() {
    this.groups = [];
    const sortedPaystubs = this.paystubs.sort((a, b) => moment(b.paid_date).diff(moment(a.paid_date)));

    const inReviewPaystubs = sortedPaystubs.filter(p => p.status === 'review');

    if (inReviewPaystubs.length > 0) {
      this.groups.push({
        title: 'IN REVIEW',
        paystubs: inReviewPaystubs,
      });
    }

    const nonReviewPaystubs = sortedPaystubs.filter(p => p.status !== 'review');

    // Create a group for each month
    const months = nonReviewPaystubs
      .map(p => moment(p.paid_date).format('MMMM YYYY'))
      .filter((v, i, a) => a.indexOf(v) === i);

    months.forEach(month => {
      this.groups.push({
        title: month,
        paystubs: nonReviewPaystubs.filter(p => moment(p.paid_date).format('MMMM YYYY') === month),
      });
    });
  }

  getPaystubs() {
    this.loading = true;

    this.paystubService.observePaystubs(this.isEmployer).subscribe(
      async (paystubs: Paystub[]) => {
        if (!paystubs) {
          this.loading = false;
          return;
        }

        this.paystubs = this.linkId ? paystubs.filter(p => p.linkId === this.linkId) : paystubs;
        await this.paystubService.loadPaystubNames(this.paystubs, this.isEmployer);
        this.createPaystubGroups();

        this.loading = false;
      },
      error => {
        console.error('An error occurred while observing paystubs:', error);
        this.loading = false;
      }
    );
  }

  ngOnInit(): void {
    this.auth.role.subscribe(() => {
      void this.getPaystubs();
    });
  }

  showPaystub(paystub: Paystub) {
    const modal = this.modalService.open(PaystubDialogComponent, { size: 'md', scrollable: true });
    modal.componentInstance.paystub = paystub;
    modal.componentInstance.member = this.isEmployer
      ? this.teamService.getHelperInfo(this.userService.getLinkHelperProfileId(paystub.linkId))
      : this.teamService.getEmployerInfo(this.userService.getLinkEmployerProfileId(paystub.linkId));
    modal.componentInstance.otherPartyName = this.paystubService.getPaystubName(paystub, this.isEmployer);
  }

  onClose() {
    this.dashboardService.setFeature('');
  }
}
