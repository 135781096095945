import { Component, OnInit } from '@angular/core';
import { TaxSummary, UserService } from '../../services/user.service';
import moment from 'moment';

@Component({
  selector: 'app-tax-summary-card',
  templateUrl: './tax-summary-card.component.html',
  styleUrls: ['./tax-summary-card.component.css'],
})
export class TaxSummaryCardComponent implements OnInit {
  taxSummary: TaxSummary;
  year = moment().year().toString();

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.taxSummary$.subscribe(taxSummary => {
      this.taxSummary = taxSummary;
    });
  }
}
