import { Address, Profile, UserService } from '../services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, ValidationErrors } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MapsAPILoader } from '@agm/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { formatPhoneNumber, formatSsn } from '../utils/string';
import moment from 'moment-timezone';
import { take } from 'rxjs/operators';
import { maskSSN } from '../utils/input';
import { formatDateWithTimezone } from '../utils/date';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  address: Address = {} as Address;
  mailingAddress: Address = {} as Address;
  ssn = '';
  fullAddress = '';
  fullMailingAddress = '';
  differentMailingAddress = false;
  formattedPhone = '';
  formattedDob = '';
  profile: Profile = {} as Profile;
  loadingProfile = true;
  loadingConfig = true;
  submitted = false;
  locked = false;
  role: string;
  error = false;
  googleOptions: Options = {
    types: ['geocode'],
    componentRestrictions: { country: 'US' },
  } as Options;
  addressComponents = {
    streetNumber: ['street_number'],
    streetName: ['route'],
    county: ['administrative_area_level_2'],
    country: ['country'],
    state: ['administrative_area_level_1'],
    postalCode: ['postal_code'],
    subLocality: ['sublocality_level_1', 'sublocality'],
    city: ['locality'],
    apartment: ['subpremise'],
  };
  ssnError: ValidationErrors | null = null;

  @ViewChild(NgForm) form!: NgForm;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private mapsAPILoader: MapsAPILoader
  ) {}

  formatAddress(address: Address) {
    return `${address?.street ?? ''}${address?.apartment ? ` ${address?.apartment}, ` : ''}, ${
      address?.city ? `${address.city}, ` : ''
    }${address?.state ? `${address.state} ` : ''}${address?.postalCode ?? ''}`;
  }

  ngOnInit(): void {
    this.userService
      .getProfile()
      .pipe(take(1))
      .subscribe(
        ({ profile }) => {
          this.ssn = profile.ssnAvailable ? `XXX-XX-${profile.ssnAvailable}` : '';
          this.address.street = profile.address?.street;
          this.address.city = profile.address?.city;
          this.address.state = profile.address?.state;
          this.address.postalCode = profile.address?.postalCode;
          this.address.subLocality = profile.address?.subLocality;
          this.address.county = profile.address?.county;
          this.address.country = profile.address?.country;
          this.address.apartment = profile.address?.apartment;
          this.fullAddress = profile.address ? this.formatAddress(profile.address) : '';

          this.differentMailingAddress = !!profile.mailingAddress?.street;

          if (this.differentMailingAddress) {
            if (!this.mailingAddress) {
              this.mailingAddress = {} as Address;
            }
            this.mailingAddress.street = profile.mailingAddress?.street;
            this.mailingAddress.city = profile.mailingAddress?.city;
            this.mailingAddress.state = profile.mailingAddress?.state;
            this.mailingAddress.postalCode = profile.mailingAddress?.postalCode;
            this.mailingAddress.subLocality = profile.mailingAddress?.subLocality;
            this.mailingAddress.street = profile.phone;
            this.mailingAddress.street = profile.mailingAddress?.county;
            this.mailingAddress.street = profile.mailingAddress?.country;
            this.address.apartment = profile.mailingAddress?.apartment;
            this.fullMailingAddress = profile.mailingAddress ? this.formatAddress(profile.mailingAddress) : '';
          }

          this.formattedPhone = formatPhoneNumber(profile.phone);
          this.formattedDob = profile.dateOfBirth.slice(0, 10);

          this.loadingProfile = false;
          this.profile = profile;
          console.log('profile', this.profile);
        },
        () => {
          this.error = true;
          this.loadingProfile = false;
        }
      );

    this.userService
      .getConfig()
      .pipe(take(1))
      .subscribe(
        configRes => {
          this.locked = !!configRes?.config?.lock?.identity;
          this.loadingConfig = false;
        },
        () => {
          this.error = true;
          this.loadingConfig = false;
        }
      );

    this.authService.role.pipe(take(1)).subscribe(r => {
      this.role = r;
    });

    void this.mapsAPILoader.load();
  }

  getComponent = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.long_name ?? '';

  getComponentShort = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.short_name ?? '';

  handleAddressChange(address: GoogleAddress) {
    const getComponent = (types: string[]) => this.getComponent(address, types);
    const getComponentShort = (types: string[]) => this.getComponentShort(address, types);

    const streetNumber = getComponent(this.addressComponents.streetNumber);
    const streetName = getComponentShort(this.addressComponents.streetName);

    this.address.street = `${streetNumber} ${streetName}`;
    this.address.county = getComponent(this.addressComponents.county);
    this.address.country = getComponentShort(this.addressComponents.country);
    this.address.state = getComponentShort(this.addressComponents.state);
    this.address.postalCode = getComponent(this.addressComponents.postalCode);
    this.address.subLocality = getComponent(this.addressComponents.subLocality);
    // Addresses in NYC don't have a city, but do have a sublocality
    this.address.city = getComponent(this.addressComponents.city) || this.address.subLocality;
    this.address.apartment = getComponent(this.addressComponents.apartment);
    this.fullAddress = this.formatAddress(this.address);
  }

  handleMailingAddressChange(address: GoogleAddress) {
    const getComponent = (types: string[]) => this.getComponent(address, types);
    const getComponentShort = (types: string[]) => this.getComponentShort(address, types);

    const streetNumber = getComponent(this.addressComponents.streetNumber);
    const streetName = getComponentShort(this.addressComponents.streetName);

    this.mailingAddress.street = `${streetNumber} ${streetName}`;
    this.mailingAddress.county = getComponent(this.addressComponents.county);
    this.mailingAddress.country = getComponentShort(this.addressComponents.country);
    this.mailingAddress.state = getComponentShort(this.addressComponents.state);
    this.mailingAddress.postalCode = getComponent(this.addressComponents.postalCode);
    this.mailingAddress.subLocality = getComponent(this.addressComponents.subLocality);
    this.mailingAddress.city = getComponent(this.addressComponents.city) || this.mailingAddress.subLocality;
    this.mailingAddress.apartment = getComponent(this.addressComponents.apartment);
    this.fullMailingAddress = this.formatAddress(this.mailingAddress);
  }

  handleValidationError(error: ValidationErrors) {
    this.ssnError = error;
  }

  onSubmit() {
    this.error = false;
    this.submitted = true;

    if (this.form.valid) {
      this.loadingProfile = true;

      this.profile.dateOfBirth = formatDateWithTimezone(this.formattedDob);
      this.profile.phone = formatPhoneNumber(this.formattedPhone);

      const updatedProfile: Profile = {
        ...this.profile,
        address: {
          street: this.address.street,
          city: this.address.city,
          state: this.address.state,
          postalCode: this.address.postalCode,
          subLocality: this.address.subLocality,
          country: this.address.country,
          county: this.address.county,
          apartment: this.address.apartment,
        },
        mailingAddress:
          this.mailingAddress && Object.keys(this.mailingAddress).length > 0
            ? {
                street: this.mailingAddress.street,
                city: this.mailingAddress.city,
                state: this.mailingAddress.state,
                postalCode: this.mailingAddress.postalCode,
                subLocality: this.mailingAddress.subLocality,
                country: this.mailingAddress.country,
                county: this.mailingAddress.county,
                apartment: this.mailingAddress.apartment,
              }
            : undefined,
      };

      const payload = this.ssn.includes('X')
        ? { profile: updatedProfile }
        : {
            profile: updatedProfile,
            ssn: formatSsn(this.ssn),
          };

      this.ssn = this.ssn ? maskSSN(this.ssn) : '';

      this.userService
        .updateProfile(payload)
        .pipe(take(1))
        .subscribe(
          () => {
            this.loadingProfile = false;
            this.submitted = false;
          },
          error => {
            console.error('Error updating profile', error);
            this.loadingProfile = false;
            this.submitted = false;
            this.error = true;
          }
        );
    }
  }
}
