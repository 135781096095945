import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-input',
  templateUrl: './toggle-input.component.html',
  styleUrls: ['./toggle-input.component.css'],
})
export class ToggleInputComponent {
  @Input() label: string;
  showInput = false;

  toggleInput() {
    this.showInput = !this.showInput;
  }
}
