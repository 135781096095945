import { Component, Input, OnInit } from '@angular/core';
import { PageResponse, PagesService } from '../../services/pages.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { injectFormId, objectToUrlParams } from '../../utils/http';
import { take } from 'rxjs/operators';
import { Profile, UserService } from '../../services/user.service';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { getIsHidden } from '../../utils/ui';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css'],
})
export class PageComponent implements OnInit {
  @Input() pageId: string;
  @Input() params: any;
  form: FormGroup;
  page: PageResponse;
  user: Profile | undefined;
  loading = true;
  success = '';
  submitted = false;
  error = '';
  warnings: { message: string }[] = [];

  protected readonly Object = Object;
  protected readonly getIsHidden = getIsHidden;

  constructor(
    private pagesService: PagesService,
    private userService: UserService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.userService
      .getProfile()
      .pipe(take(1))
      .subscribe(({ profile }) => {
        this.user = profile;
      });

    this.pagesService.getPage(this.pageId, this.params).subscribe(
      res => {
        console.log('page', res.object);
        this.page = res;
        this.loading = false;

        const controls = {};

        this.page?.object.form.sections.map(section => {
          section.rows.map(field => {
            if (field.type === 'multioption_string' || field.type === 'multioption_number') {
              const arr = field.defaultValue ? field.defaultValue : [];
              const formArray = this.fb.array(arr as string[]);

              // If the field is required, apply the required validator to each form control in the array
              if (field.required) {
                for (const control of formArray.controls) {
                  control.setValidators(Validators.required);
                  control.updateValueAndValidity();
                }
              }

              controls[field.param] = new FormControl(formArray);
            } else {
              const defaultValue =
                field.type === 'date' && field.defaultValue
                  ? moment(field.defaultValue as string).format('YYYY-MM-DD')
                  : field.defaultValue ?? '';

              // Apply the required validator conditionally based on field.required
              controls[field.param] = new FormControl(defaultValue, field.required ? Validators.required : null);
            }
          });
        });

        // Initialize the form with all controls. They may be modified within the dynamic-field component.
        setTimeout(() => {
          this.form = this.fb.group(controls);
          this.form.updateValueAndValidity();
        }, 0);
      },
      err => {
        console.error('getPage error', err.error.message);

        if (err.error.message === 'Not a valid profile, please complete profile first') {
          this.error = `We're sorry, but you must complete your profile before you can proceed.`;
        } else {
          this.error = `We're sorry, but there was an error loading the form. Please try again.`;
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );

    this.form = this.fb.group({});
  }

  onSubmit() {
    this.submitted = true;

    if (!this.form.valid) {
      console.error('form is not valid', this.form);

      // Log invalid fields
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        if (control?.invalid) {
          console.error(`Field ${field} is invalid:`, control.errors);
        }
      });
      return;
    }

    this.loading = true;

    // Remove form controls that don't have values. This fixes an issue where hidden document upload fields would cause
    // "a file name must be provided" validation errors on the server.
    this.page.object.form.sections.map(section => {
      section.rows.map(field => {
        if (this.form.get(field.param)?.value === null || this.form.get(field.param)?.value === '') {
          this.form.removeControl(field.param);
        }
      });
    });

    this.pagesService.submitPage(this.page.pageId, this.page.object.formId, this.form.value).subscribe(
      res => {
        this.loading = false;

        if (res.object?.nextStep?.type === 'thankyou') {
          this.success = res.object.nextStep.object;
          const message = this.modalService.open(MessageBoxComponent, { scrollable: true });
          message.componentInstance.title = 'Thank You';
          message.componentInstance.message = res.object.nextStep.object;

          message.result.then(() => {
            window.location.href = '/dashboard';
          });
        } else if (res.object?.nextStep?.type === 'page') {
          const { pageId, params } = res.object.nextStep.object as any;
          window.location.href = `/forms/${pageId}?${objectToUrlParams(injectFormId(params, this.params.formId))}`;
        } else if (res.object?.nextStep?.type === 'showWarning') {
          this.warnings = res.object.warnings;
        } else if (res.object?.nextStep?.type === 'deeplink') {
          const { form, params } = res.object.nextStep.object as any;
          window.location.href = `/forms/${form}?${objectToUrlParams(injectFormId(params, this.params.formId))}`;
        } else if (res.object?.nextStep?.type === 'dismiss') {
          window.location.href = 'dashboard';
        }
      },
      err => {
        this.loading = false;
        this.error = err.error?.message ?? `We're sorry, but there was an error submitting the form. Please try again.`;
      }
    );
  }

  replaceStringParams(str: string): string {
    let output = str;
    let greeting = 'Hello';

    const time = new Date().getHours();
    if (time < 12) {
      greeting = 'Good Morning';
    } else if (time < 18) {
      greeting = 'Good Afternoon';
    } else {
      greeting = 'Good Evening';
    }

    output = output?.replace(/{greetings}/g, greeting);

    const regex = /\{user\.(\w+)}/g;

    if (this.user) {
      output = output?.replace(regex, (match, propertyName) => {
        return this.user[propertyName] || match;
      });
    }

    return output;
  }
}
