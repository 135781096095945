import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { SubscriptionService, Plan, SubscriptionResponse } from '../services/subscription.service';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService, Role } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { ActivationService } from '../services/activation.service';
import { CookieService } from 'ngx-cookie-service';
import _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Address } from '../services/user.service';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';

// declare var StripeCheckout: StripeCheckoutStatic;
declare var Stripe;
declare let fbq: (...args: any[]) => any;
declare let uetq: Array<any>;

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css'],
})
export class SubscribeComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  @ViewChild('addressElement') addressElement: ElementRef;
  cardElementInitialized = false;

  address: Address = {} as Address;
  addressComponents = {
    streetNumber: ['street_number'],
    streetName: ['route'],
    county: ['administrative_area_level_2'],
    country: ['country'],
    state: ['administrative_area_level_1'],
    postalCode: ['postal_code'],
    subLocality: ['sublocality_level_1', 'sublocality'],
    city: ['locality'],
    apartment: ['subpremise'],
  };

  card: any;
  cardErrors: any;

  subResp: SubscriptionResponse;
  message = '';
  debugMessage: string;
  promoCode: string;
  firstName = '';
  lastName = '';
  fourteenDayEligible = false;

  // handler: any;
  confirmation: any;

  token: any;
  user: firebase.default.User;
  plans: Plan[];
  selectedPlan: Plan;
  subscriptionValid = false;
  livemode: Observable<boolean>;
  isLoading = true;
  stripe: any;
  referral: string;

  constructor(
    public subManager: SubscriptionService,
    public auth: AuthService,
    private router: Router,
    private cookieService: CookieService,
    public activation: ActivationService,
    private modalService: NgbModal
  ) {}

  ngAfterViewInit(): void {
    this.auth.role.subscribe(role => {
      if (role === Role.helper) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  ngAfterViewChecked(): void {
    // We need to wait for the card element to be initialized before we can set up Stripe. It doesn't get rendered until
    // the plans have loaded.
    if (this.cardElement && !this.cardElementInitialized) {
      this.setupStripe();
      this.cardElementInitialized = true;
    }
  }

  canResubscribe(): boolean {
    return this.subResp?.subscription.canResubscribe();
  }

  getComponent = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.long_name ?? '';

  getComponentShort = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.short_name ?? '';

  handleAddressChange(address: GoogleAddress) {
    const getComponent = (types: string[]) => this.getComponent(address, types);
    const getComponentShort = (types: string[]) => this.getComponentShort(address, types);

    const streetNumber = getComponent(this.addressComponents.streetNumber);
    const streetName = getComponentShort(this.addressComponents.streetName);

    this.address.street = `${streetNumber} ${streetName}`;
    this.address.county = getComponent(this.addressComponents.county);
    this.address.country = getComponentShort(this.addressComponents.country);
    this.address.state = getComponentShort(this.addressComponents.state);
    this.address.postalCode = getComponent(this.addressComponents.postalCode);
    this.address.subLocality = getComponent(this.addressComponents.subLocality);
    // Addresses in NYC don't have a city, but do have a sublocality
    this.address.city = getComponent(this.addressComponents.city) || this.address.subLocality;
    this.address.apartment = getComponent(this.addressComponents.apartment);
  }

  setupStripe() {
    const style = {
      base: {
        color: '#32325d',
        fontFamily: 'Avenir, Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        padding: '8px',
        lineHeight: '1.7',
        '::placeholder': {
          color: '#bbbbbb',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    this.stripe = Stripe(environment.stripe.key);
    const elements = this.stripe.elements();
    this.card = elements.create('card', { style, hidePostalCode: true });
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  ngOnInit() {
    this.referral = this.cookieService.get('referral');
    const o = this.subManager.getSubscriptionInfo();
    o.subscribe(resp => {
      this.handleSubResponse(resp);
    });

    this.activation.getActivation().subscribe(actResp => {
      const ret = actResp.activation.unlocks.live_payment || false;
      this.debugMessage = ret ? '' : 'Payment in Test Mode';
      this.livemode = ret;
    });

    this.subManager.getPlans().subscribe(resp => {
      this.plans = resp.plans;
      if (this.plans.length >= 2) {
        this.selectedPlan = resp.plans[0];
      } else {
        this.selectedPlan = _.first(resp.plans);
      }
      this.isLoading = false;
    });
    this.auth.userState.subscribe(user => (this.user = user));
  }

  showPlanDescriptions(e: Event, content: string) {
    e.preventDefault();
    this.modalService.open(content, { centered: true, windowClass: 'dark-', scrollable: true });
  }

  onPlanSelected(plan: any) {
    console.log(`Selected plan: ${plan.plan_name}`);
    this.selectedPlan = plan;
  }

  verifyPromoCode() {
    this.subManager.validateCoupon(this.promoCode).subscribe(
      resp => {
        if (resp.status === 'ok') {
          this.cardErrors = 'Promo Code Valid.';
        } else {
          this.cardErrors = resp.message || 'Invalid Promo Code.';
        }
      },
      err => {
        console.log(err.error);
        if (err.error.message.indexOf('Coupon already redeemed') !== -1) {
          this.cardErrors = 'Promo Code Already Redeemed.';
        } else {
          this.cardErrors = 'Invalid Promo Code.';
        }
      }
    );
  }

  validateForm() {
    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();

    if (this.firstName === '') {
      throw new Error('Must provide first name of card holder.');
    }

    if (this.lastName === '') {
      throw new Error('Must provide last name of card holder.');
    }
  }

  async handleForm(e) {
    e.preventDefault();

    try {
      this.validateForm();
    } catch (error) {
      this.cardErrors = error.message;
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    const { source, error, token } = await this.stripe.createToken(this.card, {
      name: `${this.firstName} ${this.lastName}`,
      address_line1: this.address.street,
      address_line2: this.address.apartment || '',
      address_city: this.address.city,
      address_state: this.address.state,
      address_zip: this.address.postalCode,
      address_country: this.address.country,
    });

    console.log({ name: `${this.firstName} ${this.lastName}`, address: this.address, token });
    if (error) {
      // Inform the customer that there was an error.
      this.cardErrors = error.message;
      this.isLoading = false;
    } else {
      // Send the token to server.
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.address,
        applyIapStripeGap: false,
      };

      if (this.subResp.subscription.willCancelOn) {
        data.applyIapStripeGap = true;
      }

      this.processPurchase(token, data);
    }
  }

  handleSubResponse(resp: SubscriptionResponse) {
    this.subResp = new SubscriptionResponse(resp);

    if (this.subResp.subscription) {
      this.message = this.subResp.subscription.getMessage();
      this.fourteenDayEligible = this.subResp.subscription.freeTrialEligible;
    } else {
      this.message =
        "Subscribe to the easiest, most convenient way to pay. \
        Create your first paycheck in 5 minutes. \
 				We'll do the rest, from setting up your tax \
				accounts with the IRS to handling year-end W2s. <br/><br/>";
    }
  }

  processPurchase(token: any, data) {
    this.token = token;
    console.log(token);
    this.isLoading = true;

    this.subManager
      .processTransaction(this.selectedPlan.id, this.promoCode, token.id, data)
      .pipe(
        map(res => res),
        catchError(err => {
          return throwError(err);
        })
      )
      .subscribe(
        resp => {
          fbq('track', 'CompleteRegistration');
          uetq.push('event', 'purchase', {});
          this.subResp = resp;
          if (resp.status === 'ok') {
            this.handleSubResponse(resp);
          } else {
            alert(resp.message);
          }
          this.isLoading = false;
        },
        err => {
          console.log(err.error.message || err);
          if (err.error.message) {
            alert(err.error.message);
          }
          this.isLoading = false;
        }
      );
  }
}
