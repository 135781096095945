import moment, { Moment } from 'moment';

export type Weekday = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';

// Get the last occurrence of the specified weekday, even if the end of that week is in the future.
// If today is the specified weekday, then return today.
export function getLastStartOfWeek(startOfWeek: Weekday): Moment {
  const today = moment();

  // Get the day of the week as a number (0 for Sunday, 1 for Monday, etc.)
  const todayDayOfWeek = today.day();

  // Get the day of the week as a number for the specified startOfWeek
  const startOfWeekNumber = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(
    startOfWeek.toLowerCase()
  );

  // Calculate the number of days until the last occurrence of the specified startOfWeek
  let daysUntilLastStartDay = startOfWeekNumber - todayDayOfWeek;

  if (daysUntilLastStartDay > 0) {
    // If daysUntilLastStartDay is positive, it means we are in a day of the week after the startOfWeek,
    // so we subtract 7 to go back to the previous week's startOfWeek
    daysUntilLastStartDay -= 7;
  }

  // If daysUntilLastStartDay is 0, it means today is the startOfWeek
  // If daysUntilLastStartDay is negative, it points correctly to the most recent startOfWeek

  // Calculate the last occurrence of the specified startOfWeek
  return today.clone().add(daysUntilLastStartDay, 'days');
}

// Gets the value of the most recent year in an object that has years as keys
export function getMostRecentYearData(totals: { [key: string]: number }) {
  const years = Object.keys(totals).map(Number);
  const mostRecentYear = Math.max(...years);
  return totals[mostRecentYear];
}

/**
 * Formats a date such as a date of birth or a start date, using a fixed timezone offset.
 */
export function formatDateWithTimezone(date: string | Date): string {
  if (!date) return '';

  const timezone = moment.tz.guess() || 'GMT';
  moment.tz.setDefault(timezone);
  const dateOutput = moment(date).utcOffset('-08:00');

  return dateOutput.format();
}

export function formatDateForInput(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because getMonth() returns 0-based month
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
