import { Component, Input } from '@angular/core';

export interface StepListItem {
  id: number;
  title: string;
  completed: boolean;
  checked?: boolean;
}

@Component({
  selector: 'app-step-list',
  templateUrl: './step-list.component.html',
  styleUrls: ['./step-list.component.css'],
})
export class StepListComponent {
  @Input() items: StepListItem[];
  @Input() currentStep: number;

  toggleCompletion(item: StepListItem): void {
    item.completed = !item.completed;
  }
}
