import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const navigationCharCodes = [
  8, // Backspace
  9, // Tab
  37, // Left Arrow
  39, // Right Arrow
];

export function onKeyDownNumbersOnly(event, decimals = false) {
  const charCode = event.charCode || event.keyCode;

  // Allow numeric characters 0-9
  if (charCode >= 48 && charCode <= 57) {
    return true;
  }

  // Allow one decimal point if decimals is true
  if (decimals && charCode === 46) {
    const input = event.target as HTMLInputElement;
    // Prevent multiple decimals
    if (input.value.indexOf('.') === -1) {
      return true;
    }
  }

  return false;
}

export function maskAccountNumber(s: string) {
  return s.slice(-4).padStart(s.length, '*');
}

export function maskSSN(ssn: string) {
  return ssn.replace(/(\d{3})(\d{2})(\d{4})/, 'XXX-XX-$3');
}

export function ngbDateToDate(date: NgbDateStruct): Date {
  return new Date(date.year, date.month - 1, date.day);
}

export function dateToNgbDate(date: Date): NgbDateStruct {
  return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
}
