import { Pipe, PipeTransform } from '@angular/core';
/*
 * Convert a paystub status to our preferred display text
 */
@Pipe({ name: 'paystubStatus' })
export class PaystubStatusPipe implements PipeTransform {
  transform(status: string): string {
    if (status === 'paid') {
      return 'PAID';
    }
    if (status === 'review') {
      return 'IN REVIEW';
    }
    if (status === 'canceled') {
      return 'CANCELED';
    }

    return 'UNKNOWN';
  }
}
