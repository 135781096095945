import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const navigationCharCodes = [
  8, // Backspace
  9, // Tab
  37, // Left Arrow
  39, // Right Arrow
];

export function onKeyDownNumbersOnly(event, decimals = false) {
  const charCode = event.which ? event.which : event.keyCode;

  // Allow only numbers 0-9, decimal point (optionally), or backspace
  if ((charCode < 48 || charCode > 57) && !navigationCharCodes.includes(charCode)) {
    if (decimals && (charCode === 46 || charCode === 190)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  } else {
    return true;
  }
}

export function maskAccountNumber(s: string) {
  return s.slice(-4).padStart(s.length, '*');
}

export function maskSSN(ssn: string) {
  return ssn.replace(/(\d{3})(\d{2})(\d{4})/, 'XXX-XX-$3');
}

export function ngbDateToDate(date: NgbDateStruct): Date {
  return new Date(date.year, date.month - 1, date.day);
}

export function dateToNgbDate(date: Date): NgbDateStruct {
  return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
}
