import { Component } from '@angular/core';

@Component({
  selector: 'app-tax-summary-details',
  templateUrl: './tax-summary-details.component.html',
  styleUrls: ['./tax-summary-details.component.css']
})
export class TaxSummaryDetailsComponent {

}
